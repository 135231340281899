<!--<script src="../../../../../发送/router/index.js"></script>-->
<template>
  <div class="kdjs">
    <div class="tops">
<!--      <div class="txm"><span>条形扫描</span><el-input class="sr" v-model="input" placeholder="请输入内容"></el-input></div>-->
      <div class="fn">
<!--        <el-button class="sra" @click="tz('lens')">镜片</el-button>-->
<!--        <el-button class="sra" @click="tz('frame')">镜框</el-button>-->
<!--        <el-button class="sra">老花镜</el-button>-->
<!--        <el-button class="sra" @click="tz('exam')">检查费用</el-button>-->
<!--        <el-button class="sra" @click="$router.push({path: '/corneaContactHome'})">隐形眼镜</el-button>-->
<!--        <el-button class="sra">耗材</el-button>-->

<!--        <el-button v-for="item in typeList" class="sra" @click="tz(item.keys)">{{item.val}}</el-button>-->
        <p v-for="item in typeList" @click="tz(item.keys)" class="bnts">{{item.val}}</p>
        <p class="bnts" @click="$router.push({path: '/corneaContact/corneaOrder'})">角膜塑形镜</p>
        <p class="bnts" @click="$router.push({path: '/frameWithHome/taocan'})">套餐</p>
<!--        <el-button  class="sra" @click="$router.push({path: '/corneaContact/corneaOrder'})">角膜塑形镜</el-button>-->
      </div>
      <div>
        <el-button type="primary" @click="jfcz">充值</el-button>
        <el-button type="primary" @click="go">开单</el-button>
      </div>
    </div>
    <div class="wdxz">
      <span>我的选择</span>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 1" @change="qxbh(1)" v-model="checked.checked1">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 2" @change="qxbh(2)" v-model="checked.checked2">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 3" @change="qxbh(3)" v-model="checked.checked3">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 4" @change="qxbh(4)" v-model="checked.checked4">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 5" @change="qxbh(5)" v-model="checked.checked5">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 7" @change="qxbh(7)" v-model="checked.checked7">全选</el-checkbox>
      <el-checkbox style="margin-left: 20px;" v-if="activeName == 8" @change="qxbh(8)" v-model="checked.checked8">全选</el-checkbox>
    </div>
    <div class="nr">
      <el-tabs v-model="activeName" >
        <el-tab-pane label="镜框" name="1">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup == 'frame'"  @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">
              <div class="div">
                <p style="padding-bottom: 10px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ShopProduct.SPShopPrice}}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ShopProduct.SPShopPrice}}</P>-->
                <P>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</P>
              </div>
              <div class="div" style="padding: 0 12vw">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <span @click.stop="dialogVisible=true">处方数据</span>
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount"></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="镜片" name="2">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup == 'lens'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice}}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.CHPLeftRightEyes"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in ybList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select
                      size="small"
                      class="srr"
                      @change="jpup($event, idx)"
                      v-model="item.CHPS_OP_ALL_Id"
                  >
                    <el-option
                        v-for="item in jpfa"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="zj">
                  <el-select
                      placeholder="直径"
                      size="small"
                      class="srr"
                      v-model="item.LensDia"
                      @change="upzj($event, idx)"
                  >
                    <el-option
                        v-for="item in jkzj"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select
                      placeholder="通道"
                      size="small"
                      class="srr"
                      v-model="item.ChannelLength"
                      @change="uptd($event, idx)"
                  >
                    <el-option
                        v-for="item in tdop"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
<!--                  <el-input size="small" type="number" style="width: 100px;" @blur="uptd($event, idx, item.ChannelLength)"  v-model="item.ChannelLength" placeholder="通道"></el-input>-->
                </div>
                <div style="text-align: left">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              </div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <span @click.stop="dialogVisible=true">处方数据</span>
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="药品" name="3">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup === 'drug'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.CHPLeftRightEyes"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in ybList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="text-align: center; width: 120px;text-align: left;">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
                <!--                <p style="opacity: 0">价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>-->
              </div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="yyxqfn(idx)" size="small">用药详情</el-button>
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="手术收费" name="4">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup === 'operation'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.CHPLeftRightEyes"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in ybList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="text-align: center; width: 120px;text-align: left;">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
                <!--                <p style="opacity: 0">价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>-->
              </div>
              <div  class="last div">
                <el-button v-if="0" type="primary" style="margin-right: 20px;" @click.stop="yyxqfn(idx)" size="small">用药详情</el-button>
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="治疗收费" name="5">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup === 'treatment'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div style="text-align: center; width: 120px;text-align: left;">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
                <!--                <p style="opacity: 0">价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>-->
              </div>
              <div  class="last div">
                <el-button  v-if="0" type="primary" style="margin-right: 20px;" @click.stop="yyxqfn(idx)" size="small">用药详情</el-button>
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="检查收费" name="7">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup === 'exam'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div style="text-align: center; width: 120px;text-align: left;">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
                <!--                <p style="opacity: 0">价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>-->
              </div>
              <div  class="last div">
                <el-button type="primary"  v-if="0" style="margin-right: 20px;" @click.stop="yyxqfn(idx)" size="small">用药详情</el-button>
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="其他" name="8">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList"
                 v-if="item.CHPProductSortGroup !== 'lens' && item.CHPProductSortGroup !== 'frame' && item.CHPProductSortGroup !== 'drug' && item.CHPProductSortGroup !== 'exam' && item.CHPProductSortGroup !== 'operation' && item.CHPProductSortGroup !== 'treatment'"
                 @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" v-if="0" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.count"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in cishu"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select
                      size="small"
                      class="srr"
                      @change="jpup($event, idx)"
                      v-model="item.time"
                  >
                    <el-option
                        v-for="item in shijian"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="text-align: left">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              </div>
              <div class="div" v-if="item.CHPProductSortGroup !== 'rgp' || item.CHPProductSortGroup !== 'contactglass' || item.CHPProductSortGroup !== 'oklens'" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.CHPLeftRightEyes"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in ybList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select
                      size="small"
                      class="srr"
                      @change="jpup($event, idx)"
                      v-model="item.CHPS_OP_ALL_Id"
                  >
                    <el-option
                        v-for="item in qtcf"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>

                <div style="text-align: left">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              </div>


              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" v-if="item.CHPProductSortGroup !== 'exam'" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-collapse v-if="0" v-model="list" accordion>
        <el-collapse-item name="1" title="镜框">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup == 'frame'"  @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">
              <div class="div">
                <p style="padding-bottom: 10px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
<!--                <P>价格：{{item.ProductRelation.ShopProduct.SPShopPrice}}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ShopProduct.SPShopPrice}}</P>-->
                <P>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</P>
              </div>
              <div class="div" style="padding: 0 12vw">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <span @click.stop="dialogVisible=true">处方数据</span>
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount"></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item name="2" title="镜片" >
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup == 'lens'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
<!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice}}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
<!--                  <el-select-->
<!--                      size="small"-->
<!--                      class="srr"-->
<!--                      v-model="item.CHPLeftRightEyes"-->
<!--                      @change="jpup($event, idx)"-->
<!--                  >-->
<!--                    <el-option-->
<!--                        v-for="item in ybList"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                  <el-select-->
<!--                      size="small"-->
<!--                      class="srr"-->
<!--                      @change="jpup($event, idx)"-->
<!--                      v-model="item.CHPS_OP_ALL_Id"-->
<!--                  >-->
<!--                    <el-option-->
<!--                        v-for="item in jpfa"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
                </div>
                <div style="text-align: left">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              </div>


              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <span @click.stop="dialogVisible=true">处方数据</span>
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item  name="3" title="药品">
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup === 'drug'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
                <!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" style="padding-right: 10vw;padding-left: 2vw;">
                <div style="text-align: center; width: 120px;text-align: left;">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
<!--                <p style="opacity: 0">价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>-->
              </div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="yyxqfn(idx)" size="small">用药详情</el-button>
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
                <!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item name="4" title="其他" >
          <div class="nr-box">
            <div class="fxk-box" v-for="(item, idx) in gwcList" v-if="item.CHPProductSortGroup !== 'lens' && item.CHPProductSortGroup !== 'frame' && item.CHPProductSortGroup !== 'drug'" @click="xz(idx)">
              <div class="fxksp"><i v-if="checkList.some((item) => item==idx)" class="el-icon-check"></i></div>
              <img :src="item.ProductRelation.ProductDetail.AllProduct.ProductPicFullURL" alt="">

              <div class="div">
                <p style="padding-bottom: 5px">{{item.ProductRelation.ProductDetail.Product.ProductName}}</p>
<!--                <P>价格：{{item.ProductRelation.ProductDetail.Product.ProductOfficalPrice }}<span v-if="item.ProductRelation.ProductDetail.Product.ProductUnit">/</span>{{item.ProductRelation.ProductDetail.Product.ProductUnit}}</P>-->
                <p>价格：￥{{item.ProductRelation.ShopProduct.SPShopPrice}}</p>
              </div>

              <div class="div" v-if="0" style="padding-right: 10vw;padding-left: 2vw;">
                <div class="zj">
                  <el-select
                      size="small"
                      class="srr"
                      v-model="item.count"
                      @change="jpup($event, idx)"
                  >
                    <el-option
                        v-for="item in cishu"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select
                      size="small"
                      class="srr"
                      @change="jpup($event, idx)"
                      v-model="item.time"
                  >
                    <el-option
                        v-for="item in shijian"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="text-align: left">总价：￥{{item.ProductRelation.ShopProduct.SPShopPrice * item.CHPAmount}}</div>
              </div>
              <div  class="last div">
                <el-button type="primary" style="margin-right: 20px;" @click.stop="getkc(idx)" size="small">库存</el-button>
<!--                <span @click.stop="dialogVisible=true">处方数据</span>-->
                <el-input-number style="width: 90px; margin-left: 20px;" :min="1" size="mini" v-if="item.CHPProductSortGroup !== 'exam'" @change="bh(item.ChooseProductId, item.CHPAmount)" v-model="item.CHPAmount" ></el-input-number>
                <i @click.stop="delegwc(idx)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>

      </el-collapse>


    </div>


    <el-dialog
        title="处方方案"
        :visible.sync="dialogVisible"
        width="70%">
      <div>
        <el-select
            class="srr"
            v-model="zffav"
            @change="fabh"
            filterable
            allow-create
            collapse-tags
            default-first-option
        >
          <el-option
              v-for="item in zffa"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="fasj">
        <div v-if="obj.S_OP_Json.S_OP_IO_Type === '0'">
          <div class="srA">
            <div>
              <ul class="content-sr mar-20">
                <li></li>
                <li>球镜</li>
                <li>柱镜</li>
                <li>轴向</li>
                <li>远用矫正视力</li>
                <li>ADD</li>
              </ul>
              <ul class="content-sr">
                <li>OD</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DSOD" :disabled='true' placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DVAOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_ADDOD" :disabled="true" placeholder=""></el-input></li>
              </ul>
              <ul class="content-sr mar-20">
                <li>OS</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DSOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DVAOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_ADDOS" :disabled="true" placeholder=""></el-input></li>
              </ul>
            </div>
          </div>

          <div class="srA">
            <div>
              <ul class="content-sr mar-20">
                <li></li>
                <li>近用矫正视力</li>
                <li>瞳距</li>
                <li>瞳高</li>
                <li>棱镜</li>
                <li>基底</li>
              </ul>
              <ul class="content-sr">
                <li>OD</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_NVAOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PDOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PHOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_ArriseOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_BasisOD" :disabled="true" placeholder=""></el-input></li>
              </ul>
              <ul class="content-sr mar-20">
                <li>OS</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_NVAOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PDOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PHOS" :disabled="true"  placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_ArriseOS" :disabled="true"  placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_BasisOS" :disabled="true"  placeholder=""></el-input></li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="obj.S_OP_Json.S_OP_IO_Type === '2'">
          <div class="srA">
            <div>
              <ul class="content-sr mar-20">
                <li></li>
                <li>球镜</li>
                <li>柱镜</li>
                <li>轴向</li>
                <li>近用视力矫正</li>
                <li>瞳距</li>
              </ul>
              <ul class="content-sr">
                <li>OD</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DSOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_NVAOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PDOD" :disabled="true" placeholder=""></el-input></li>
              </ul>
              <ul class="content-sr mar-20">
                <li>OS</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DSOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_NVAOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_PDOS" :disabled="true" placeholder=""></el-input></li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="obj.S_OP_Json.S_OP_IO_Type === '7'">
          <div class="srA">
            <div>
              <ul class="content-sr mar-20">
                <li></li>
                <li>球镜</li>
                <li>柱镜</li>
                <li>轴向</li>
                <li>视力</li>
                <li>基弧</li>
                <li>镜片直径</li>
              </ul>
              <ul class="content-sr">
                <li>OD</li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DSOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_VAOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_BCOD" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DIMOD" :disabled="true" placeholder=""></el-input></li>
              </ul>
              <ul class="content-sr mar-20">
                <li>OS</li>
                <li><el-input  v-model="obj.S_OP_Json.S_OP_IO_DSOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DCOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_XOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_VAOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_BCOS" :disabled="true" placeholder=""></el-input></li>
                <li><el-input v-model="obj.S_OP_Json.S_OP_IO_DIMOS" :disabled="true" placeholder=""></el-input></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        title="用药详情"
        :visible.sync="yyxqshow"
        width="60%">
      <ul class="yyxq">
        <li>
          <p>用药周期</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_Days"
          >
            <el-option
                v-for="item in yyts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>用药方式</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_UseMethod"
          >
            <el-option
                v-for="item in yyfs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <ul class="yyxq">
        <li>
          <p>用药频次</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_CountPerDay"
          >
            <el-option
                v-for="item in cishu"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>用药规律</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_TimeInDay"
          >
            <el-option
                v-for="item in shijian"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <ul class="yyxq">
        <li>
          <p>眼别</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.LeftOrRight"
          >
            <el-option
                v-for="item in yyyanbie"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>每次用量</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_CountPerTime"
          >
            <el-option
                v-for="item in yyyl"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>


      <el-button type="primary" style="margin-top: 20px;" @click.stop="jpup($event, '')" size="small">提交</el-button>
    </el-dialog>

    <el-dialog
        title="库存"
        :visible.sync="showkc"
        width="70%">
      <ul class="kcym">
        <li>产品名称</li>
        <li>门店</li>
        <li>数量</li>
      </ul>
      <div style="height: 50vh;overflow-y: auto;">
        <ul class="kcym" v-for="item in kcList">
          <li>{{kcname}}</li>
          <li>{{item.ShopName}}</li>
          <li v-if="item.SPbNoCountSale == 0">{{item.SPAmount}}</li>
          <li v-if="item.SPbNoCountSale == 1">定制</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import pf from "../../publicFn/baseFn"
export default {
  name: "kdjs",
  data() {
    return {
      activeName: '1',
      yyxqshow: false,
      ypitem: {},
      ypidx: 0,
      list: ['1', '2', '3', "4"],
      checked: {
        checked1:false,
        checked2:false,
        checked3:false,
        checked4:false,
        checked5:false,
        checked7:false,
        checked8:false
      },
      checkedlistval: '',
      typeList: [],
      input: '',
      checkList: [],
      dialogVisible: false,
      showkc: false,
      kcList: [],
      kcname: '',
      kzfc: '',
      zffa: [],
      zffav: '',
      falist: [],
      tdop: [],
      obj: {S_OP_Json: {S_OP_IO_Type: ''}},
      gwcList: [],
      qtcf: [],
      ybList: [
        {
          value: 'OD',
          label: '右眼'
        },
        {
          value: 'OS',
          label: '左眼'
        },
        {
          value: 'OU',
          label: '双眼'
        },
        {
          value: '',
          label: ''
        }
      ],
      ybv: '',
      jpfa: [],
      jpfaV: '',
      cishu: [
        {
          value: '1次/2小时',
          label: '1次/2小时'
        },
        {
          value: '1次/天',
          label: '1次/天'
        },
        {
          value: '2次/天',
          label: '2次/天'
        },
        {
          value: '3次/天',
          label: '3次/天'
        },
        {
          value: '4次/2小时',
          label: '4次/2小时'
        },
        {
          value: '',
          label: ''
        },
      ],
      jkzj: [
          {
            value: '50',
            label: '50'
         },
        {
          value: '55',
          label: '55'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '65',
          label: '65'
        },
        {
          value: '70',
          label: '70'
        },
        {
          value: '75',
          label: '75'
        },
        {
          value: '80',
          label: '80'
        },
        {
          value: '90',
          label: '90'
        },
        {
          value: '95',
          label: '95'
        }
      ],
      shijian: [
        {
          value: '睡前',
          label: '睡前'
        },
        {
          value: '日间',
          label: '日间'
        },
        {
          value: '',
          label: ''
        },
      ],
      yyts: [
        {
          value: '1天',
          label: '1天'
        },
        {
          value: '2天',
          label: '2天'
        },
        {
          value: '3天',
          label: '3天'
        },
        {
          value: '4天',
          label: '4天'
        },
        {
          value: '5天',
          label: '5天'
        },
        {
          value: '6天',
          label: '6天'
        },
        {
          value: '7天',
          label: '7天'
        },
        {
          value: '10天',
          label: '10天'
        },
        {
          value: '2周',
          label: '2周'
        },
        {
          value: '3周',
          label: '3周'
        },
        {
          value: '1个月',
          label: '1个月'
        },
        {
          value: '3个月',
          label: '3个月'
        },
        {
          value: '半年',
          label: '半年'
        },
        {
          value: '长期',
          label: '长期'
        },
        {
          value: '',
          label: ''
        }
      ],
      yyfs: [
        {
          value: '口服',
          label: '口服'
        },
        {
          value: '外用冷敷',
          label: '外用冷敷'
        },
        {
          value: '外用热敷',
          label: '外用热敷'
        },
        {
          value: '滴眼',
          label: '滴眼'
        },
        {
          value: '涂抹',
          label: '涂抹'
        },
        {
          value: '',
          label: ''
        }
      ],
      cishuVal: '',
      shijianVal: '',
      yyyl: [
        {
          value: '一次1滴/片/粒',
          label: '一次1滴/片/粒'
        },
        {
          value: '一次2滴/片/粒',
          label: '一次2滴/片/粒'
        },
        {
          value: '一次3滴/片/粒',
          label: '一次3滴/片/粒'
        },
        {
          value: '',
          label: ''
        }
      ],
      yyyanbie: [
        {
          value: 'OU',
          label: 'OU'
        },
        {
          value: 'OS',
          label: 'OS'
        },
        {
          value: 'OD',
          label: 'OD'
        },
        {
          value: '',
          label: ''
        }
      ]
    }
  },
  computed: {
    les() {

    }
  },
  created() {
    if (!this.$store.state.physicianVisits.xzConsulting) {
      return this.$alert('顾客未登录，请登录')
      .then(res => {
        this.$router.push({path: '/csLanding'})
      })
    }
    if (!this.$store.state.users.employees) {
      return this.$alert('员工未登录，请登录')
          .then(res => {
            this.$router.push({path: '/employeesLanding'})
          })
    }

    let arrs = []
    for (let i = 5; i < 17; i++) {
      arrs.push({
        value: i,
        label: i
      })
    }
    this.tdop = [...arrs]


    // this.cshTime('trade_bills')

    this.getfa()
    this.getgwc()


    this._http.post(this._getUrl('Sort_GetListInCart'), {
      "token": {
        sToken: "E0B1486293C779EEE7CD24AF277703D7",
        TimeSpan: "1574670974"
      }
    })
    .then(res => {
      // console.log(res)
      if (res.ReturnCode !== 5000) {
        return this.$alert('获取类目失败！', '提示')
      }
      let arr = [];
      for (let key in res.Result) {
        arr.push({
          keys: key,
          val: res.Result[key]
        })
      }
      // console.log(res.Result)
      this.typeList = arr
    })
  },
  methods: {
    fabh(e) {
      this.obj = this.falist[Number(e)]
    },

    getfa() {
      this._api.expertClinic.getViewPlan(1)
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              let datas = res.GetListResult,arr = [], arr2 = []
              for (let i = 0; i < datas.length; i++) {
                datas[i].S_OP_Json = this._clJson(datas[i].S_OP_Json)
                if (datas[i].S_OP_Json.S_OP_IO_Type == '0') {
                  arr.push({
                    value: i,
                    label: '框架远用'
                  })
                  arr2.push({
                    value: datas[i].S_OP_ID,
                    label: '框架远用'
                  })
                }else if (datas[i].S_OP_Json.S_OP_IO_Type == '2') {
                  arr.push({
                    value: i,
                    label: '框架近用'
                  })
                  arr2.push({
                    value: datas[i].S_OP_ID,
                    label: '框架近用'
                  })
                }else if (datas[i].S_OP_Json.S_OP_IO_Type == '7') {
                  arr.push({
                    value: i,
                    label: '角膜接触镜'
                  })
                  arr2.push({
                    value: datas[i].S_OP_ID,
                    label: '角膜接触镜'
                  })
                }
              }
              this.zffa = JSON.parse(JSON.stringify(arr))
              this.falist = datas
              this.jpfa = arr2
            }
          })
    },

    getqtfa() {
      this._api.expertClinic.S_OP_ALL_GetCLIOList()
      .then(res => {
        if (res.Result) {
          let arrs = []
          for (let key in res.Result) {
            arrs.push({
              label: key,
              value: res.Result[key]
            })
          }
          this.qtcf = [...arrs]
        }
      })
    },

    getgwc() {
      this._http.post(this._getUrl('getChooseList'), {
        "token": {
          sToken: "E0B1486293C779EEE7CD24AF277703D7",
          TimeSpan: "1574670974"
        },
        SearchCondition: {
          IndexMin: 0,
          IndexMax: 0,
          // CustomerId: this.$store.state.users.CsUser.CustomerId,
          ChooseId: this.$store.state.physicianVisits.xzConsulting,
          ShopId: this.$store.state.bases.shop.ShopId,
          "CHPStatus":"1"
        },
        "ReturnInfo":{
          "S_OP_ALL":1,//增加这行
          "ProductRelation":{
            "Product":{},
            "ShopProduct":1
          }
        }
      })
      .then(res => {
        // console.log(res.GetListResult)
        if (res.GetListResult && res.GetListResult.length) {
          let obj = {
            c1: [],
            c2: [],
            c3: [],
            c4: [],
            c5: [],
            c7: [],
            c8: []
          }
          let datas = res.GetListResult
          for (let i=0; i < datas.length; i++) {
            if (datas[i].CHPProductSortGroup == 'lens') {
              if (datas[i].CHPS_OP_ALL_Id  == '00000000-0000-0000-0000-000000000000') {
                datas[i].CHPS_OP_ALL_Id = ''
              }
              obj.c2.push(i)
            }

            if (datas[i].CHPProductSortGroup == 'drug') {
              obj.c3.push(i)
              if (datas[i].CHPPrescriptionData) {
                let di = pf.decoding(datas[i].CHPPrescriptionData)
                datas[i].Drug_CountPerDay = di.Drug_CountPerDay
                datas[i].Drug_TimeInDay = di.Drug_TimeInDay
                datas[i].Drug_Days = di.Drug_Days
                datas[i].Drug_UseMethod = di.Drug_UseMethod
                datas[i].LeftOrRight = di.LeftOrRight
                datas[i].Drug_CountPerTime = di.Drug_CountPerTime
              }

            }

            if (datas[i].CHPProductSortGroup == 'frame') {obj.c1.push(i)}

            if (datas[i].CHPProductSortGroup == 'operation') {obj.c4.push(i)}
            if (datas[i].CHPProductSortGroup == 'treatment') {obj.c5.push(i)}
            if (datas[i].CHPProductSortGroup == 'exam') {obj.c7.push(i)}

            if (datas[i].CHPProductSortGroup != 'lens' && datas[i].CHPProductSortGroup != 'drug' && datas[i].CHPProductSortGroup != 'frame' && datas[i].CHPProductSortGroup != 'operation' && datas[i].CHPProductSortGroup != 'treatment' && datas[i].CHPProductSortGroup != 'exam') {
              obj.c8.push(i)
            }
          }
          this.gwcList = JSON.parse(JSON.stringify(datas))
          this.checkedlistval = obj
        }
        this.getqtfa()
      })
    },

    yyxqfn(idx) {
      this.ypidx = idx
      this.ypitem = JSON.parse(JSON.stringify(this.gwcList[idx]))
      // console.log(this.ypitem)
      this.yyxqshow = true
    },

    jpup(e, idx) {
      if (this.ypitem.CHPProductSortGroup == 'drug' ) {
        this.gwcList[this.ypidx] = JSON.parse(JSON.stringify(this.ypitem))
        let datasp = JSON.parse(JSON.stringify(this.ypitem))
        datasp.CHPS_OP_ALL_Id?datasp.CHPS_OP_ALL_Id='00000000-0000-0000-0000-000000000000':''
        let items = {
          Drug_CountPerDay: datasp.Drug_CountPerDay,
          Drug_TimeInDay: datasp.Drug_TimeInDay,
          Drug_Days: datasp.Drug_Days,
          Drug_UseMethod: datasp.Drug_UseMethod,
          LeftOrRight: datasp.LeftOrRight,
          Drug_CountPerTime: datasp.Drug_CountPerTime
        }
        datasp.CHPPrescriptionData = pf.conversionJson(items)
        this._http.post(this._getUrl('UpdateChooseProduct'), {
          "token": {
            "sToken": "E0B1486293C779EEE7CD24AF277703D7",
            "TimeSpan": "1574670974"
          },
          o: {
            CHPPrescriptionData: datasp.CHPPrescriptionData,
            CHPChooseId: datasp.CHPChooseId,
            CHPProductId: datasp.CHPProductId,
            CHPStatus: 1
          }
        })
            .then(res => {
              if (res.UpdateResult) {
                setTimeout(()=> {
                  this.$alert('确认成功')
                }, 500)

              }
            })
      }else {
        this._http.post(this._getUrl('UpdateChooseProduct'), {
          "token": {
            "sToken": "E0B1486293C779EEE7CD24AF277703D7",
            "TimeSpan": "1574670974"
          },
          o: this.gwcList[idx]
        })
            .then(res => {
              if (res.UpdateResult) {
                setTimeout(()=> {
                  this.$alert('确认成功')
                }, 500)

              }
            })
      }

    },

    upzj(e, idx) {

      if (!this.gwcList[idx].CHPLeftRightEyes || !this.gwcList[idx].CHPS_OP_ALL_Id) {
        return this.$alert('请绑定双眼或方案！', "提示")
      }


      let lng = this.$loading({
        text: '修改中'
      })


      this._http.post(this._getUrl('ChooseProduct_UpdateLensDia'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o": {
          "ChooseProductId": this.gwcList[idx].ChooseProductId,//购物车Id
          "CHPS_OP_ALL_Id": this.gwcList[idx].CHPS_OP_ALL_Id,//绑定的处方Id
          "CHPLeftRightEyes": this.gwcList[idx].CHPLeftRightEyes,//左右眼
          "SetLensDia": e//直径
        }
      })
      .then(res => {
        lng.close()
        this.$alert(res.displaymsg, "提示")
      })
    },

    uptd(e, idx) {
      if (!this.gwcList[idx].CHPLeftRightEyes || !this.gwcList[idx].CHPS_OP_ALL_Id) {
        return this.$alert('请绑定双眼或方案！', "提示")
      }

      let lng = this.$loading({
        text: '修改中'
      })

      // console.log(this._getUrl('ChooseProduct_UpdateLensChannelLength'))

      this._http.post(this._getUrl('ChooseProduct_UpdateLensChannelLength'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o": {
          "ChooseProductId": this.gwcList[idx].ChooseProductId,//购物车Id
          "CHPS_OP_ALL_Id": this.gwcList[idx].CHPS_OP_ALL_Id,//绑定的处方Id
          "CHPLeftRightEyes": this.gwcList[idx].CHPLeftRightEyes,//左右眼
          "SetLensChannelLength": e//通道
        }
      })
          .then(res => {
            lng.close()
            this.$alert(res.displaymsg, "提示")
          })
    },

    delegwc(idx) {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '删除中...'
      });
      this._http.post(this._getUrl('deleteProductFromList'), {
        token : {
              sToken: "E0B1486293C779EEE7CD24AF277703D7",
              TimeSpan: "1574670974"
            },
        ChooseProductId : this.gwcList[idx].ChooseProductId
    })
      .then(res => {
        loadingInstance.close()
        if (res.DeleteByIdResult) {
          this.gwcList.splice(idx, 1)
          return this.$alert('删除成功！')
        }
        this.$alert('删除失败！')
      })
    },

    xz(idx) {
      this._api.publicApi.getIsm(this.gwcList[idx].CHPProductId)
          .then(res => {
            if (res.msg == 'Failed') {
               return this.$alert(res.FailMsg, '提示')
            }
            if (this.checkList.indexOf(idx) == -1) {
              this.checkList.push(idx)
            }else {
              this.checkList.splice(this.checkList.indexOf(idx), 1)
            }
          })
    },

    go() {
      let str = ''

      if (this.checkList.length == 0) return this.$alert('请选择商品！')
      for (let i = 0; i < this.checkList.length; i++) {
        str += this.gwcList[this.checkList[i]].ChooseProductId+','
        if (this.gwcList[this.checkList[i]].CHPProductSortGroup == 'lens') {
          if (!this.gwcList[this.checkList[i]].CHPLeftRightEyes) {
            return this.$alert('请选择眼别！')
          }
          if (this.gwcList[this.checkList[i]].CHPS_OP_ALL_Id == '' || this.gwcList[this.checkList[i]].CHPS_OP_ALL_Id == '00000000-0000-0000-0000-000000000000') {
            return this.$alert('请选择处方！')
          }
        }
      }

      if ( this.$store.state.Time.timeObj.trade_bills.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.trade_bills.ENTER)/1000 + Number(this.$store.state.Time.timeObj.trade_bills.EXIT)
        this._api.publicApi.addTime('trade_bills,trade_payment', times)
        this.jsTime('trade_bills', 1)
      }

      let spid = this.$store.state.bases.shop.ShopId,
          choosid = this.$store.state.physicianVisits.xzConsulting,
          ysid = this.$store.state.users.employees.ExpertId,
          csid = this.$store.state.users.CsUser.CustomerId,
          CompanyId = this.$store.state.users.user.Company.CompanyId,
          userid = this.$store.state.users.user.UserId
      let src = `${this._getUrl('CustomerOrderConfirm')}&ShopId=${spid}&ExpertId=${ysid}&CompanyId=${CompanyId}&ChooseId=${choosid}&CustomerId=${csid}&UserId=${userid}&ChooseProductId=${str}`
      // window.open()
      this.$router.push({path: '/Iframe', query:{type:13, src: src}})
      // window.location.href=src
    },

    tz(type) {
      // if (type === 'contactglass') return this.$router.push({path: '/corneaContactHome'})
      this.$router.push({path: '/frameWithHome/frameChoose', query: {
          type: type
        }})
    },

    jfcz() {
      this.$router.push({path: '/Iframe', query:{type:18}})
    },

    getkc(idx) {
      this.kcname = this.gwcList[idx].ProductRelation.ProductDetail.AllProduct.ProductName
      this._api.publicApi.getKc(this.gwcList[idx].CHPProductId)
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              this.showkc = true
              this.kcList = [...res.GetListResult]
            }
          })
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },
    bh(id, num=1) {
      if (num < 1) num = 1
      this._http.post(this._getUrl('UpdateChooseProductAmount'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o":{
          "CHPAmount":num,
          "ChooseProductId":id
        }
      })
          .then(res => {
            if (res.msg !== 'Success') {
              this.$alert('增加数量失败！', '提示')
            }
          })
    },

    qxbh(idx) {
      let arr = []
      switch (idx) {
        case 1:
          if (this.checkedlistval.c1.length < 1) {return;}
          if (!this.checked.checked1) return this.deleqx(this.checkedlistval.c1)
          arr = []
          for (let i = 0; i < this.checkedlistval.c1.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c1[i]].CHPProductId)
          }
          this.qxteb('checked1', arr.join(','), this.checkedlistval.c1)
          break
        case 2:
          if (this.checkedlistval.c2.length < 1) return
          if (!this.checked.checked2) return this.deleqx(this.checkedlistval.c2)
          for (let i = 0; i < this.checkedlistval.c2.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c2[i]].CHPProductId)
          }
          this.qxteb('checked2', arr.join(','), this.checkedlistval.c2)
          break
        case 3:
          if (this.checkedlistval.c3.length < 1) return
          if (!this.checked.checked3) return this.deleqx(this.checkedlistval.c3)
          arr = []
          for (let i = 0; i < this.checkedlistval.c3.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c3[i]].CHPProductId)
          }
          this.qxteb('checked3', arr.join(','), this.checkedlistval.c3)
          break
        case 4:
          if (this.checkedlistval.c4.length < 1) return
          if (!this.checked.checked4) return this.deleqx(this.checkedlistval.c4)
          arr = []
          for (let i = 0; i < this.checkedlistval.c4.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c4[i]].CHPProductId)
          }
          this.qxteb('checked4', arr.join(','), this.checkedlistval.c4)
          break
        case 5:
          if (this.checkedlistval.c5.length < 1) return
          if (!this.checked.checked5) return this.deleqx(this.checkedlistval.c5)
          arr = []
          for (let i = 0; i < this.checkedlistval.c5.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c5[i]].CHPProductId)
          }
          this.qxteb('checked5', arr.join(','), this.checkedlistval.c5)
          break
        case 7:
          if (this.checkedlistval.c7.length < 1) return
          if (!this.checked.checked7) return this.deleqx(this.checkedlistval.c7)
          arr = []
          for (let i = 0; i < this.checkedlistval.c7.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c7[i]].CHPProductId)
          }
          this.qxteb('checked7', arr.join(','), this.checkedlistval.c7)
          break
        case 8:
          if (this.checkedlistval.c8.length < 1) return
          if (!this.checked.checked8) return this.deleqx(this.checkedlistval.c8)
          arr = []
          for (let i = 0; i < this.checkedlistval.c8.length; i++) {
            arr.push(this.gwcList[this.checkedlistval.c8[i]].CHPProductId)
          }
          this.qxteb('checked8', arr.join(','), this.checkedlistval.c8)
          break
      }
    },

    qxteb(idx, str, arr) {
      let lng = this.$loading({
        text:"加载中..."
      })
      this._api.Company.ShopProduct_IsProductCanSellList(str)
      .then(res => {
        lng.close()
        if (res.msg == 'Success') {
          this.checkList.push(...arr)
        }else {
          this.$alert(res.FailMsg, '提示')
          this.checked[idx] = false
        }
      })
    },

    deleqx (arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < this.checkList.length; j++) {
          if (arr[i] == this.checkList[j]) {
            this.checkList.splice(j, 1)
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.trade_bills.ENTER != 0) {
      this.jsTime('trade_bills')
    }
  }
}
</script>

<style scoped lang="scss">
  .yyxq {
    display: flex !important;
    //flex-wrap: ;
    justify-content: center;
    li {
      padding-left: 15px;
      padding-bottom: 10px;
      p {
        padding-bottom: 5px;
      }
    }
  }
  .kdjs {
    width: 100vw;
    height: 76vh;
  }
  .tops {
    display: flex;
    //align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .txm {
      display: flex;
      align-items: center;
      justify-content: start;
      .sr {width: 200px}
      span {padding-right: 10px;}
    }
    .fn {
      width: 65vw;
      display: flex;
      flex-wrap: wrap;
      .sra {width: 100px; margin: 5px;}
    }
  }
  .wdxz {
    padding:0 20px;
    padding-bottom: 5px;
    text-align: left;
    border-bottom: 1px solid #5a5959;
  }
  .nr {
    padding: 0 20px;
    .nr-box {
      height: calc(100vh - 480px);
      overflow-y: auto;
      .fxk-box {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px 0;
        border-bottom: 1px solid #c2c2c2;
        margin-bottom: 2px;
        .div {
          font-size: 14px;
          p {text-align: left;}
          p:nth-child(1) {
            width: 23vw;
          }
        }
        .fxksp {
          width: 15px;
          height: 15px;
          border: 1px solid #40a7f6;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #40a7f6;
        }
        img {
          width: 110px;
          margin: 0 2vw;
        }
        span {color: #40a7f6;}
        .last {
          display: flex;
          align-items: center;
          i {font-size: 23px; margin-left: 5vw}
        }
      }
    }
  }
  .fasj {
    height: 40vh;
    overflow-y: auto;
  }
  .zj {
    display: flex;
    align-items: center;
    //justify-content: center;
    padding-bottom: 5px;
    .srr {
      width: 100px;
      margin-right: 10px;
    }
  }
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 9vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3em;
        //padding-right: .rem;
        justify-content: start;
        //margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-top: 32px;
    }
  }
  .mar-20{margin-top: 20px}
  .kcym {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li:nth-child(1) {width: 38%;padding-right: 2%;}
    li:nth-child(2) {width: 38%;padding-right: 2%;}
    li:nth-child(3) {width: 20%}
    border-bottom: 1px solid #adadad;
    padding: 10px 0;
  }
  .bnts {
    width: 6em;
    padding: 8px 0;
    //height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(11,3,6,0.2);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    &:hover {
      box-shadow: inset 0px 3px 9px 0px rgba(25,122,255,0.5);
      color: #019CFF;
    }
  }
</style>
